@import '../../sass/imports' ;

.section__cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2em;
  margin: -6em 0;
 
  section {
    
    padding: 2em;
    border-radius: 4px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .5);
    width: 22em;
    height: 27em;

    @include device(small) {
      margin: 0px;
      margin-bottom: 2em;
    }
    
  }

  h1 {
    font-size: 32px;
    margin-top: 0px;
  }

  @include device(small) {
    flex-wrap: wrap;
  }
}
@import '../../sass/imports';

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}

.navElements {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: $font-2;
}

.logo {
  width: 250px;
  height: 60px;
  position: relative;
  z-index: 999;
}

.navElements li a {
  color: $white;
  margin-right: 2rem;
  text-decoration: none;
  font-weight: normal;
  cursor: pointer;
  font-family: $font-2;
  transition: all .2s ease-in-out;

  &:hover {
    color: $p-1;
    transform: scale(1.1);
  }
}

a.btnModify {
  padding: .4em 1.5em;
  border-radius: 6px;
}

.mobileNav--active li {
  font-size: 1rem;
  margin-top: .5rem;
}

.mobileNav {
  background: $c-10;
  z-index: 10;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -2em;
  left: -100%;
  transition: all .2s;
  text-align: center;
}

.mobileNav--active {
  left: 0;
}

.hamburger__menu {
  margin: 2rem;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  z-index: 999;
}

.bar__top,
.bar__middle,
.bar__bottom {
  height: 5px;
  background: white;
  border-radius: 5px;
  margin: 3px 0;
  transform-origin: left;
  transition: all 0.5s;
}

.bar__top--active {
  transform: rotate(45deg);
}

.bar__middle--active {
  transform:translateX(1rem);
  opacity: 0;
}

.bar__bottom--active {
  transform: rotate(-45deg);
}

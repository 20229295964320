@import '../../../../sass/imports';

.team__card {
  width: 800px;
  padding: 20px;
  display: grid;
  align-items: center;
  grid-template-columns: 1.2fr 2fr 4fr;
  background-color: $c-12;
  border-radius: 7px;
  border: 1px solid $black;
  margin-bottom: 20px;

  p {
    font-family: $font-2;
    font-size: 16px;
    line-height: 18px;
    width: 400px;

    @include device(medium) {
      width: 200px;
    }

    @include device(small) {
      width: 200px;
      text-align: center;
    }
  }

  h3 {
    text-align: center;
    @include device(small) {
     
      margin-bottom: 10px;
    }
  }

  img {
    filter: saturate(0);
    border-radius: 7px;
  }

  a {
    font-family: $font-2;
    font-weight: bold;
    color: $white;
    text-transform: uppercase;
    letter-spacing: .5px;

    &:visited {
      color: $white;
    }
  }

  @include device(medium) {
    width: 600px;
  }

  @include device(small) {
    width: 100%;
    grid-template-columns: 1fr;
  }
}

.team__text__decorator {
  h1 {
    font-size: 55px;
    text-transform: uppercase;
    text-align: right;
    line-height: 60px;
    width: 300px;
    word-wrap: break-word;
    color: $c-05;

    @include device(small) {
      text-align: center;
      margin-bottom: 80px;
    }
  }
}

.flip-card {
  background-color: transparent;
  width: 150px;
  height: 150px;
  perspective: 1000px;
  cursor: pointer;
  text-align: center;
  border-radius: 7px;
  
  @include device(small) {
    width: 100px;
    height : 100px;
    margin: 10px auto;
  }
}

.flip-card .inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card .inner .front,
.flip-card .inner .back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 7px;
}

.flip-card .inner .front {
  background: $gradient-2;
}

.flip-card .inner .back {
  background: $gradient-2;
  transform: rotateY(180deg);
}

.flip-card:hover .inner {
  transform: rotateY(180deg);
}

@import '../../../sass/_imports';
@import './Slide/slide.scss';

.carroussel {
  padding-top: 100px;
  padding-bottom: 80px;
  @include tipo-2(30);
  background-color: $c-10;

  a {
    color: $white;
  }

  h1 {
    text-align: center;
    margin-bottom: 80px;
    font-family: $font-1;
    font-size: 46px;
    line-height: 50px
  };

  h2 {
    margin-top: 40px;
    margin-bottom: 10px;
    text-align: left;
    font-family: $font-1;
    font-size: 24px;
    line-height: 26px;
    @include device(small) {
      font-size: 18px;
      line-height: 20px;
    }
  }

  p {
    text-align: left;
    font-weight: normal;
    @include device(small) {
      font-size: 16px;
    } 
  }
}

@import './sass/imports';

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  padding-top: 2rem;
  margin: 0px;
  color: $white;
  background: $black;
  @include tipo-1(18);
  z-index: -900;
  background-repeat: no-repeat;
  background-position: -35em -2em,  50em 1em, 50% 60em;
  background-size: auto, auto, 90%;
  overflow: hidden;
  overflow-y: auto;
}

h1, 
h2, 
h3, 
h4, 
p {
  margin: 0px;
} 

a {
  text-decoration: none;
}

p {
  font-size: 16px;
}

ul, li {
  list-style: none;
  padding: 0px;
  margin: 0px ;
}

img {
  display: block;
  max-width: 100%;
}

button, input {
  display: block;
  font-size: 1rem;
}

.container {
  max-width: 80rem;
  padding: 0 1rem;
  margin: 0 auto;
}

.animeLeft {
  opacity: 0;
  transform: translateX(-40px);
  animation: animeLeft .3s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}
@import '../../../sass/imports';

.team {
  background: $c-11;
  padding: 100px 0;
}

.team__container {
  max-width: 80rem;
  padding: 0 0rem;
  margin: 0 auto;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  @include device(small) {
    flex-direction: column-reverse;
    justify-content: center;
  }
}

@import '../../../sass/imports';

.banner {
  left: -15px;
  max-height: 65vh;
  display: flex;
  width: 100%;
  
  h1 {
    font-family: $font-1;
    z-index: 5;
    font-size: 3.6em;
    line-height: 1.05em;
    width: calc($lg-device / 2);
    position: relative;

    @include device(small) {
      font-size: 3em;
      line-height: 1em;
      text-align: center;
      width: 100%;
    }

    .banner__text__decorator {
      position: relative;
      z-index: 4;

        &::before {
          content: '';
          position: absolute;
          width: 50px;
          height: 50px;
          display: block;
          left: -15px;
          top: -5px;
          z-index: -1;
          background: url('../../../assets/decorador.svg') no-repeat;

          @include device(small) {
            left: -25px;
          }
        }
    }
  }

  div {
    margin-top: 18vh; 
    margin-bottom: 4vh; 
  }

  p {
    font-family: $font-2;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 40px;
    @include device(small) {
      font-size: 16px;
      text-align: center;
    }
  }

  a {
    display: inline-block;
    margin-top: 10px;
    background: transparent;
    padding: .3em 1.5em;
    border-radius: 6px;
    font-weight: 400;
    @include tipo-2(18);

    &:hover {
      cursor: pointer;
    }
  }

  button {
    @include device(small) {
      margin: 0 auto;
    }
  }

  @include device(small) {
    background: url('../../../assets/decorador_escuro.svg');
    background-repeat: round;
  }
}

.banner__arrow {
  display: block;
  border: none;
  text-align: center;
  margin: 20px auto;
  width: 50px;
  height: 35px;
  background: url('../../../assets/arrow.svg') no-repeat;
  animation: moveArrow 1s infinite;
}

.banner__border__decorator {
  position: relative;

  &:after {
    position: absolute;
    content: ''; 
    width: 102%; 
    border: 2px solid ;
    border-radius: 6px;
    border-color: $p-1;
    top: 10px;
    left: -5px;
    height: 56px;

    @include device(small) {
      top: 8px;
      height: 46px;
    }
  }
}

.banner__image {
  text-align: center;
  width: 45vw;
  height: 400px;
  margin-left: 2vw;
  background: url('../../../assets/home.svg') no-repeat  right center;
  background-size: contain;

  @include device(medium) {
    width: 400px;
    background-size: contain;
    background-position: center;
  }

  @include device(small) {
    display: none;
  }
}

@keyframes moveArrow {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0,20px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@import '../../../sass/imports';

button {
  font-family: $font-2;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .8px;
  background: $gradient-1;
  border: none;
  padding: 14px 24px;
  border-radius: 6px;
  color: $white;
  transition: all .2s ease-in-out;

  &:hover {
    background: linear-gradient(270deg, rgba(105,41,187,1) 0%, rgba(160,81,222,1) 100%);
    cursor: pointer;
  }
}
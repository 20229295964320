@import '../../../sass/imports';

.contact {
  margin-top: 100px;
}
.contact__decorator {
  height: 160px;
  background: url('../../../assets/fale_conosco.svg');
  background-repeat: repeat-x;

  &:nth-child(1) {
    background-position: right;
  }
}

.contact__card {
  padding: 8px;
  width: 100%;
  background-color: $white;
  color: $black;
  border-radius: 7px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;

  @include device(small) {
    grid-template-columns: 1fr;
  }
}

.contact__data {
  background: $gradient-1;
  padding: 40px;
  border-radius: 7px;
  height: 100%;
  margin-right: 16px;

  @include device(small) {
    margin-right: 0;
  }

  h2 {
    color: $white;
    margin-bottom: 16px;
  }
  
  p {
    line-height: 20px;
    color: $white;
    margin-bottom: 16px;
  }

  ul {
    margin-top: 80px;
  }

  li {
    display: flex;
    align-items: top;
    margin-bottom: 16px;
  }

  img {
    margin-right: 16px;
  }

  a {
    cursor: pointer;
  }
}

.contact__text__decorator {
  position: relative;
  z-index: 4;

    &::before {
      content: '';
      position: absolute;
      width: 35px;
      height: 30px;
      display: block;
      left: -14px;
      top: -5px;
      z-index: -1;
      background: url('../../../assets/decorador_escuro.svg') no-repeat;
      background-size: contain;

      @include device(small) {
        left: -25px;
      }
    }
}

.contact__socialmedia {
  margin-top: 80px;
  display: flex;
  justify-content: space-around;
  padding: 20px;

  
}
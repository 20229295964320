.galeria {
  grid-column: 1/ -1;
}

[data-slide="slide_wrapper"] {
  overflow: hidden;
  grid-column: 1/ -1;
}

[data-slide="slide"] {
  display: flex;

  &:hover {
    will-change: transform;
  }

  li {
    flex-shrink: 0;
    width: 80vw;
    max-width: 800px;
    border-radius: 4px;
    overflow: hidden;
    opacity: .9;
    transform: scale(.8);
    transition: .4s;
    cursor: pointer;
  }
  
  .active {
    opacity: 1;
    transform: scale(1);
  }
}

.custom-controls {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-column: 1 / -1;
}

.custom-controls li {
  transform: scale(.8);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin: 2px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .5);
  transition: .3s;
}
.custom-controls li.active {
  opacity: 1;
  transform: scale(1);
}
.custom-controls li > img {
  cursor: pointer;
}
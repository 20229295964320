@import '../../../../sass/imports';

.wrapper {
  margin-bottom: 1rem;
  width: 99%;

}

[name="textarea"] {
  height: 4em;
  word-break: break-word;
}

.input {
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: .8rem;
  transition: .2s;
  box-shadow: none;
  outline: none;
  border: none;
  border-bottom: 2px solid $c-05;
  margin-bottom: 16px;
  &:hover,
  &:focus {
    outline: none;
  }

}

.label {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: .5rem;
  font-family: $font-1;
  font-weight: bold;
}

.error {
  color: #f31;
  font-size: .875rem;
  margin-top: -8px;
}


@import '../../../sass/imports';

.benefits {
  h1 {
    font-family: $font-1;
    font-size: 3.5em;
    line-height: 1.05em;
    text-align: center;
    margin-bottom: 80px;
    margin-top: 100px;
  }

  .benefits__text__decorator {
    position: relative;

    &::after {
      position: absolute;
      display: block;
      content: '';
      width: 35px;
      height: 8px;
      top: 53px;
      border-radius: 4px;
      right: -34px;
      background: $gradient-1;

    }
  }
  
  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 100px;
  }

  li {
    text-align: center;
    width: 290px;
    height: 250px;
    background: $gradient-1;
    margin: 10px;
    padding: 20px;
    border-radius: 7px;
    img {
      margin: 0 auto;
      margin-bottom: 8px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
@import '../../../../sass/imports';

.form {
  max-width: 50em;
  position: relative;
  padding: 8px;

  @include device(small) {
    padding-top: 80px;
  }

  h1 {
    text-align: center;
    margin: 2em 0;
    @include tipo-1(30);
  }

  button {
    margin-top: 32px;
  }

  .form__two_elements {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 8px;

    @include device(small) {
      display: block;
    }
  }
}

@import '../../../sass/imports';

.about {
  width: 100%;
  background-color: $c-11;
  section {
    margin-top: 100px;
    display: flex;
    h1 {
      font-family: $font-1;
      font-size: 144px;
      width: 500px;
      line-height: 150px;
      word-wrap: break-word;
      color: $c-10;

      @include device(medium) {
        font-size: 100px;
        width: 400px;
      }

      @include device(small) {
        font-size: 30px;
        width: 100px;
        line-height: 30px;
      }
    }

    p {
      font-family: $font-2;
      margin-bottom: 40px;
      line-height: 24px;
      @include device(small) {
        text-align: center;
      }
    }

    .about__text {
      width: 50vw;

      @include device(small) {
        width: 100vw;
      }

    }

    div.about__decoration {
      width: 50vw;

      @include device(small) {
        display: none;
      }
    
      @include device(medium) {
        width: 350px;
      }
    }

    ul {
      display: flex;
      margin: 0;
      padding: 0;
      flex-wrap: wrap;
      text-align: center;

      @include device(small) {
        justify-content: center;
      }
    }

    li {
      display: block;
      background-color: $c-12;
      margin-right: 20px;
      margin-bottom: 20px;
      width: 250px;
      border-radius: 7px;
      padding: 24px;
      height: 80px;
      padding-bottom: 0;
      position: relative;
      border: 1px solid $black;

      &::after {
        position: relative;
        content: '';
        display: block;
        width: 3px;
        background: $gradient-1;
        height: 24px;
        left: -27px;
        top: -40px;
      }

      @include device(medium) {
        width: 200px;
        margin-right: 10px;
        margin-bottom: 10px;
        line-height: 16px;

        &::after {
          top: -20px;
        }
      }
    }
  }
}

.about__container {
  max-width: 80rem;
  padding: 100px 1rem;
  margin: 0 auto;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&family=Roboto:wght@300;400;500;700&display=swap');

// Colors
$gradient-1: linear-gradient(180deg, rgba(105,41,187,1) 0%, rgba(160,81,222,1) 100%);
$gradient-2: linear-gradient(180deg, rgba(122,25,168,1) 0%, rgba(184,69,224,1) 100%);

$p-1: #BC00FF;
$p-2: #7A19A8;
$p-3: #380163;
$p-4: #1B0031;
$p-5: #0F011A;

$c-01: #F5F7FA;
$c-02: #E6EAF0;
$c-03: #CED3D9;
$c-04: #C0C5CC;
$c-05: #B2B8BF;
$c-06: #989EA6;
$c-07: #7E838C;
$c-08: #636973;
$c-09: #474D59;
$c-10: #363B47;
$c-11: #242833;
$c-12: #0B0C12;

$black: #000000;
$white: #FFFFFF;


// Fonts
$font-1: 'Raleway', sans-serif;
$font-2: 'Roboto', sans-serif;

// Sizes
$lg-device: 1200px;
$md-device: 760px;
$sm-device: 480px; 

//Function and Mixins
@function em($pixel, $context: 16) {
  @return calc($pixel / $context) + em;
}

@mixin tipo-1($size) {
  font-family: $font-1;
  @if $size == 18 {
    font-size: em(18);
    line-height: em(30, 18);
  }

  @if $size == 30 {
    font-size: em(30);
    line-height: em(40, 30);
  }

  @if $size == 40 {
    font-size: em(40);
    line-height: em(50, 40);
  }
}

@mixin tipo-2($size) {
  font-family: $font-2;
  @if $size == 18 {
    font-size: em(18);
    line-height: em(30, 18);
  }  

  @else if $size == 24 {
    font-size: em(24);
    line-height: em(30, 24);
  }

}

// Mixin de media-querie
@mixin device($size) {
  @if $size == large {
      @media screen and (min-width: $lg-device) {
          @content;
      }
  }
  @else if $size == medium {
      @media screen and (min-width: $md-device) and (max-width: #{$lg-device - 1}) {
          @content;
      }
  }
  @else if $size == small {
      @media screen and (max-width: #{$md-device - 1}) {
          @content;
      }
  }
}
.wrapper {
  display: grid;
}

.img {
  display: block;
  max-width: 100%;
  grid-area: 1 / 1;
  opacity: 0;
  transition: .2s;
}

.skeleton {
  grid-area: 1 / 1;
  height: 100%;
  background-image: linear-gradient(90deg, rgb(110, 110, 110) 0px, #fff 50%, rgb(110, 110, 110) 100%  );
  background-color: #eee;
  background-size: 200%;
  animation: skeleton 1.5s infinite linear;
}

@keyframes skeleton {
  from {
    background-position: 0px;
   }
   to {
    background-position: -200%;
   }
}
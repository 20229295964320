@import '../../../sass/imports';

.howitworks {
  background-color: $c-11;
  padding-top: 100px;
  padding-bottom: 100px;

  h1 {
    font-family: $font-1;
    z-index: 5;
    font-size: 4em;
    line-height: 1.05em;
    width: calc($lg-device / 2);
    position: relative;

    @include device(small) {
      font-size: 3em;
      line-height: 1em;
      text-align: center;
      width: 100%;
    }

    .howitworks__text__decorator {
      position: relative;
      z-index: 4;

        &::before {
          content: '';
          position: absolute;
          width: 50px;
          height: 50px;
          display: block;
          left: -15px;
          top: 5px;
          z-index: -1;
          background: url('../../../assets/decorador.svg') no-repeat;

          @include device(small) {
            left: -25px;
          }
        }
    }
  }

  h3 {
    font-size: 24px;
    font-family: $font-2;
    color: $c-05;
    margin-top: 16px;
  }

  ul {
    display: flex;
    margin-top: 24px;
    padding: 0;
    flex-wrap: wrap;
    text-align: left;
  }

  li {
    display: block;
    background-color: $c-03;
    color: $black;
    font-family: $font-2;
    border: $c-05;
    font-weight: bold;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 7px;
    padding: 16px;
    height: 100%;
    padding-bottom: 0;
    position: relative;
    padding-left: 24px;

    &::after {
      position: relative;
      content: '';
      display: block;
      width: 3px;
      background: $gradient-1;
      height: 24px;
      left: -27px;
      top: -40px;
    }
  }
}

.howitworks__container {
  max-width: 80rem;
  padding: 0 1rem;
  margin: 0 auto;
  display: flex;

  @include device(small) {
    flex-wrap: wrap;
  }
}

.howitworks__title {
  @include device(small) {
    text-align: center;
    margin: 0 auto;
  }
}

.howitworks__list {
  @include device(small) {
    margin-top: 40px;
  }
}
